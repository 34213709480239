import { useState } from 'react';

const useIngestWorkflow = (url: string, accessToken:string) => {
  const [ingestJobId, setIngestJobId] = useState(null);
  const [ingestJobStatus, setIngestJobStatus] = useState(null);

  const launchIngestWorkflow = async (fileId: string) => {
    try {
      const response = await fetch(`${url}/webapi/user/v2/workflows/${import.meta.env.import.meta.env.VITE_ALTERYX_WORKFLOW_ID}/jobs`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          credentialId: '637a2a09360a37bf2e8198ff',
          questions: [{ name: 'File Browse (1114)', value: fileId }],
          priority: 'High',
        }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setIngestJobId(data.id);
      return data.id;
    } catch (error) {
      console.error('Failed to launch ingest workflow');
    }
  };

  const checkIngestJobStatus = async (jobId:string) => {
    try {
      const response = await fetch(`${url}/webapi/v1/jobs/${jobId}?includeMessages=false`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setIngestJobStatus(data);
      return data;
    } catch (error) {
      console.error('Failed to check ingest job status');
    }
  };

  return { launchIngestWorkflow, checkIngestJobStatus, ingestJobId, ingestJobStatus };
};

export default useIngestWorkflow;